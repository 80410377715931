<template>
    <div>
        <main class="container-fluid">
            <div class="row mx-md-4">
				<!-- Breadcrumb -->
				<div class="col-md-12 mb-4">
					<div class="card shadow border-0 round">
						<div class="card-body px-2 py-1">
							<nav aria-label="breadcrumb">
								<ol class="breadcrumb bg-transparent mb-0">
									<li class="breadcrumb-item text-info"><router-link to="/" class=" text-decoration-none">Home</router-link></li>
									<li class="breadcrumb-item active" aria-current="page">Website</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
				
				<div class="col-md-3 mb-4">
					<SidebarPanduan />
				</div>
				
				<div class="col-md-9 mb-4">
					<div class="card shadow border-0 text-secondary round px-5 py-4">
						<div class="text-center mb-4">
							<h2>Website</h2>
						</div>
						<div class="text-left">
							<p>Duniamurah.com merupakan website e-commerce resmi dari PT. Putra Perdana Sentosa yang telah berpengalaman dalam penyediaan kebutuhan berbagai produk alat listrik dan teknik serta jasa  selama lebih dari 10 tahun.</p>
							<p>Dengan pengalaman tersebut, Duniamurah.com berkeyakinan dapat menyediakan kebutuhan alat-alat listrik dan teknik untuk konsumen kami.Disamping itu dengan dukungan staf yang berpengalaman serta dukungan teknologi terdepan menjadikan Duniamurah.com sebagai solusi terbaik dalam penyediaan alat-alat listrik dan teknik.</p>
							<p>Peralatan yang dijual di Duniamurah.com mendapatkan garansi resmi dari principal dan garansi dari Duniamurah.com sendiri.</p>
						</div>
					</div>
				</div>
			</div>
        </main>
    </div>
</template>

<script>
import SidebarPanduan from '@/components/SidebarPanduan.vue'

export default {
	components: {
  		SidebarPanduan
	},
	created() {
		$(document).scrollTop(0)
	} 
}
</script>